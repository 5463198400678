import React, { useState } from 'react'
import { Button, Table } from 'react-bootstrap'
import { CSVLink } from 'react-csv'
import AWS from 'aws-sdk'
import { AWS_CONFIG } from '../config'

AWS.config.update({
  accessKeyId: AWS_CONFIG.accessKeyId,
  secretAccessKey: AWS_CONFIG.secretAccessKey,
  region: AWS_CONFIG.region,
})

const lambda = new AWS.Lambda()

function ResultsTable({ resultsObject }) {
  const results = resultsObject?.data || []
  const [greyedOutRows, setGreyedOutRows] = useState({})
  const [isSaving, setIsSaving] = useState(false)

  const invokeLambda = async (id, data) => {
    const params = {
      FunctionName: AWS_CONFIG.lambdaTaskedToUpdate,
      InvocationType: 'RequestResponse',
      Payload: JSON.stringify({ id, data }),
    }

    try {
      const result = await lambda.invoke(params).promise()
      console.log('result from second lambda', result)
      alert('Record updated successfully')
      console.log('payload->', JSON.parse(result.Payload))
    } catch (error) {
      console.error('Error invoking Lambda function:', error)
      alert('Failed updating record')
      return null
    }
  }

  const handleRemoveClick = (index) => {
    setGreyedOutRows((prev) => ({
      ...prev,
      [index]: !prev[index],
    }))
  }

  const handleSave = async () => {
    setIsSaving(true)
    const updatedResults = results.map((row, index) =>
      greyedOutRows[index] ? { ...row, archived: true } : row,
    )

    console.log('Updated Results:', updatedResults)

    // Send updatedResults to the backend
    const id = resultsObject.id
    const response = await invokeLambda(id, updatedResults)

    setIsSaving(false)
  }

  const headers = [
    { label: 'Product Name', key: 'product_name_en' },
    { label: 'Receipt Code', key: 'receiptCode' },
    { label: 'NOVA Group', key: 'off:nova_groups' },
    { label: 'EcoScore', key: 'off:ecoscore_score' },
    { label: 'Food Group', key: 'off:food_groups' },
    { label: 'NutriScore', key: 'off:nutriscore_score' },
    { label: 'Allergens', key: 'allergens' },
    { label: 'Ingredients', key: 'ingredients_text_en' },
  ]

  const csvData = results
    .filter((_, index) => !greyedOutRows[index])
    .map((result) => ({
      ...result,
      'off:nova_groups': result['off:nova_groups'] || 'N/A',
      'off:ecoscore_score': result['off:ecoscore_score'] || 'N/A',
      'off:food_groups': result['off:food_groups'] || 'N/A',
      'off:nutriscore_score': result['off:nutriscore_score'] || 'N/A',
      allergens: result.allergens || 'N/A',
      ingredients_text_en: result.ingredients_text_en || 'N/A',
    }))

  if (!results.length) {
    return (
      <div className="container my-1">
        <div className="alert alert-info text-center" role="alert">
          No results to display
        </div>
      </div>
    )
  }

  const atleastOneRowhasbeenArchived = Object.values(greyedOutRows).some(
    (value) => value === true,
  )

  return (
    <div className="container">
      <div className="my-1">
        <h1>Information Retrieved</h1>
        <div className="d-flex justify-content-end my-1">
          <CSVLink
            headers={headers}
            data={csvData}
            filename="results.csv"
            className="btn btn-success me-2"
          >
            <i className="fa fa-file-excel-o" /> CSV
          </CSVLink>
          <Button variant="primary" onClick={handleSave} disabled={isSaving}>
            {isSaving ? (
              <i className="fa fa-spinner fa-spin" />
            ) : (
              <i className="fa fa-save" />
            )}{' '}
            Update Selection
          </Button>
        </div>
      </div>
      <div className="table-responsive">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Action</th>
              <th>Product Name</th>
              <th>Receipt Code</th>
              <th>NOVA Group</th>
              <th>EcoScore</th>
              <th>Food Group</th>
              <th>NutriScore</th>
              <th>Allergens</th>
              <th>Ingredients</th>
            </tr>
          </thead>
          <tbody>
            {results.map((result, index) => (
              <tr
                key={index}
                className={greyedOutRows[index] ? 'greyed-out' : ''}
              >
                <td>
                  <Button
                    variant="danger"
                    onClick={() => handleRemoveClick(index)}
                  >
                    {greyedOutRows[index] ? 'Add' : 'Ignore'}
                  </Button>
                </td>
                <td>{result.product_name_en}</td>
                <td>{result.receiptCode}</td>
                <td>{result['off:nova_groups'] || 'N/A'}</td>
                <td>{result['off:ecoscore_score'] || 'N/A'}</td>
                <td>{result['off:food_groups'] || 'N/A'}</td>
                <td>{result['off:nutriscore_score'] || 'N/A'}</td>
                <td>{result.allergens || 'N/A'}</td>
                <td>{result.ingredients_text_en || 'N/A'}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  )
}

export default ResultsTable
