import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Button, Container, Row, Col } from 'react-bootstrap'
import SweetAlert from 'react-bootstrap-sweetalert'
import AWS from 'aws-sdk'
import { AWS_CONFIG } from '../config'

AWS.config.update({
  accessKeyId: 'AKIAXYKJRMO47TSYT7HO',
  secretAccessKey: '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',
  region: AWS_CONFIG.region,
})

const s3 = new AWS.S3()
const lambda = new AWS.Lambda()

function FileUpload({ onFileUpload, resultsFetched, onClear, setLoading }) {
  const [file, setFile] = useState(null)
  const [showClearAlert, setShowClearAlert] = useState(false)
  const [uploadClicked, setUploadClicked] = useState(false)

  const onDrop = (acceptedFiles) => {
    if (!resultsFetched) {
      setFile(acceptedFiles[0])
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*,application/pdf',
    maxFiles: 1,
    disabled: resultsFetched,
  })

  const uploadToS3 = async (file) => {
    const params = {
      Bucket: AWS_CONFIG.bucketName,
      Key: file.name,
      Body: file,
    }

    try {
      await s3.upload(params).promise()
      return file.name
    } catch (error) {
      console.error('Error uploading file to S3:', error)
      return null
    }
  }

  const invokeLambda = async (fileName) => {
    const params = {
      FunctionName: AWS_CONFIG.lambdaFunctionName,
      InvocationType: 'RequestResponse',
      Payload: JSON.stringify({ filename: fileName }),
    }

    try {
      const result = await lambda.invoke(params).promise()
      return JSON.parse(result.Payload)
    } catch (error) {
      console.error('Error invoking Lambda function:', error)
      return null
    }
  }

  const handleUpload = async () => {
    setUploadClicked(true)
    if (file) {
      setLoading(true)
      const fileName = await uploadToS3(file)
      console.log('fileName->', fileName)
      if (fileName) {
        const res = await invokeLambda(fileName)
        const results = JSON.parse(res)
        console.log('resss', results)
        onFileUpload(results)
      }
      setLoading(false)
    }
  }

  const handleClear = () => {
    setShowClearAlert(true)
  }

  const handleConfirmClear = () => {
    setFile(null)
    onClear()
    setShowClearAlert(false)
    setUploadClicked(false)
  }

  const handleCancelClear = () => {
    setShowClearAlert(false)
  }

  return (
    <Container>
      {!resultsFetched && (
        <Row>
          <Col>
            <div
              {...getRootProps()}
              className={`dropzone ${isDragActive ? 'active' : ''}`}
            >
              <input {...getInputProps()} />
              <p>
                {isDragActive
                  ? 'Drop the files here ...'
                  : 'Drag & drop a receipt image or PDF, or click to select one'}
              </p>
            </div>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          {file && (
            <div className="preview">
              {file.type.startsWith('image/') ? (
                <img
                  src={URL.createObjectURL(file)}
                  alt="Preview"
                  width="100%"
                />
              ) : (
                <p>{file.name}</p>
              )}
            </div>
          )}
        </Col>
      </Row>
      <Row className="my-1">
        <Col>
          {resultsFetched ? (
            <Button variant="warning" onClick={handleClear}>
              <i className="fa fa-times"></i> Clear Selection
            </Button>
          ) : (
            <div>
              <Button variant="primary" onClick={handleUpload}>
                Go
              </Button>
              {uploadClicked && !file && (
                <p className="text-danger mt-2">Please select a file.</p>
              )}
            </div>
          )}
        </Col>
      </Row>
      <SweetAlert
        show={showClearAlert}
        warning
        showCancel
        confirmBtnText="Yes, clear it"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        title="Are you sure?"
        onConfirm={handleConfirmClear}
        onCancel={handleCancelClear}
      >
        You will lose the uploaded file and the results. Are you sure you want
        to clear?
      </SweetAlert>
    </Container>
  )
}

export default FileUpload
