import React, { useEffect, useRef, useState } from 'react'
import FileUpload from './components/FileUpload'
import ResultsTable from './components/ResultsTable'
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'

function App() {
  const [results, setResults] = useState({})
  const [loading, setLoading] = useState(false)
  const bottomRef = useRef(null)

  const handleFileUpload = async (uploadedResults) => {
    setResults(uploadedResults)
    setLoading(false)
  }

  const handleClear = () => {
    setResults({})
    setLoading(false)
  }

  useEffect(() => {
    if (results?.data?.length > 0) {
      console.log('scrolling down')
      // window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
      bottomRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [results])

  return (
    <div>
      <div className="container-fluid bg-light text-dark p-5">
        <div className="container bg-light p-2">
          <h1 className="display-4 fw-bold">Receipt Upload</h1>
          <p>Upload your receipt and get the details of your purchase.</p>
        </div>
      </div>
      <FileUpload
        onFileUpload={handleFileUpload}
        resultsFetched={results?.data?.length > 0}
        onClear={handleClear}
        setLoading={setLoading}
      />
      {loading ? (
        <div className="text-center">
          <i className="fa fa-spin fa-spinner fa-3x"></i>
        </div>
      ) : (
        <div className="text-center">
          <div ref={bottomRef}></div>
          <ResultsTable resultsObject={results || {}} />
        </div>
      )}
    </div>
  )
}

export default App
